import React, { Component } from 'react'
import _, { update } from 'lodash'

import CustomSnackbar from 'components/Snackbar';

import { Get, Post, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    state = {
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      loading: false,
      projectObj: {
        project_name: '',
        project_description: '',
        implementing_agency: '',
        assessor: '',
        project_leader: '',
        current_stage: '',
        start_year: '',
        funder: '',
        state: '',
        status: '',
        activity_report: [],
        kpi_indication: [],
        achievement_indication: [],
        disbursement_progress: [],
        utilization_progress: [],
        stage_and_milestone: [],
        assessment: []
      },
      selectedMilestoneIndex: {},
      selectedStageIndex: {},
      showSubmissionModal: false,
    }

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
        callback && callback()
      },
      snackbarMessage: success
    })
    onChangePerformanceHOC = ( val, context ) => this.setState({ [ context ]: val })

    getProjectPerformance = id => Get(
      `/project-getProject?project_id=${ id }`,
      this.getProjectPerformanceSuccess,
      this.getProjectPerformanceError,
      this.load
    ) 
    getProjectPerformanceSuccess = payload => {
      let temp = _.cloneDeep( payload.data )
      if( temp.stage_and_milestone && temp.stage_and_milestone.length > 0 ){
        temp.stage_and_milestone.map(( stage ) => stage[ 'onShow' ] = false )
      }

      this.setState({ projectObj: temp })
    }
    getProjectPerformanceError = error => this.requestError( error )

    updateProjectPerformance = dataToSubmit => Post(
      `/project-updateProjectPerformance`,
      dataToSubmit,
      this.updateProjectPerformanceSuccess,
      this.updateProjectPerformanceError,
      this.load
    )
    updateProjectPerformanceSuccess = payload => {
      this.setState({ showSubmissionModal: false })
      this.props.getProjectDetail( this.props.projectDetail.project_id )
      this.requestSuccess( 'Project Performance update recorded' )
    }
    updateProjectPerformanceError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props } 
            { ...this.state }
            onLoadPerformance={ this.state.loading }

            getProjectPerformance={ this.getProjectPerformance }
            updateProjectPerformance={ this.updateProjectPerformance }
            onChangePerformanceHOC={ this.onChangePerformanceHOC }/>
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType }
          />
        </>
      )
    }
  }
  return WithHOC;
}

export default HOC