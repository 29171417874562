import React, { Component } from 'react'

import CustomSnackbar from 'components/Snackbar';

import { Get, Post, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    state = {
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      loading: false,
      showEditModal: false,
      showCreateModal: false,
      showDeleteConfirmation: false,
      showApproveConfirmation: false,

      kpi: [],
      selectedKPI: {}
    }

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
        callback && callback()
      },
      snackbarMessage: success
    })
    onChangeKPIHOC = ( val, context ) => this.setState({ [ context ]: val })

    getKPIs = kpis => Get(
      `/kpi-getKPIs?kpis=${ JSON.stringify( kpis ) }`,
      this.getKPIsSuccess,
      this.getKPIsError,
      this.load
    )
    getKPIsSuccess = payload => this.setState({ kpi: payload.data.kpi })
    getKPIsError = error => this.requestError( error )

    createNewKPI = dataToSubmit => Post(
      `/kpi-newKPI`,
      dataToSubmit,
      this.createNewKPISuccess,
      this.createNewKPIError,
      this.load
    )
    createNewKPISuccess = payload => {
      this.requestSuccess( payload.data.message )
      this.setState({ showCreateModal: false })
      this.props.getProjectDetail( this.props.projectDetail.project_id )
    }
    createNewKPIError = error => this.requestError( error )
  
    updateKPI = dataToSubmit => Post(
      `/kpi-updateKPI`,
      dataToSubmit,
      this.updateKPISuccess,
      this.updateKPIError,
      this.load
    )
    updateKPISuccess = payload => {
      this.requestSuccess( payload.data.message )
      this.setState({ 
        showEditModal: false,
        showApproveConfirmation: false,
      })
      this.props.getProjectDetail( this.props.projectDetail.project_id )
    }
    updateKPIError = error => this.requestError( error )

    deleteKPI = id => Delete (
      `/kpi-deleteKPI?id=${ id }`,
      this.deleteKPISuccess,
      this.deleteKPIError,
      this.load
    )
    deleteKPISuccess = payload => {
      this.requestSuccess( payload.data.message )
      this.setState({ showDeleteConfirmation: false })
      this.props.getProjectDetail( this.props.projectDetail.project_id )
    }
    deleteKPIError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props } 
            { ...this.state }
            onLoadKPI={ this.state.loading }

            getKPIs={ this.getKPIs }
            updateKPI={ this.updateKPI }
            deleteKPI={ this.deleteKPI }
            createNewKPI={ this.createNewKPI }
            onChangeKPIHOC={ this.onChangeKPIHOC }/>
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType }
          />
        </>
      )
    }
  }
  return WithHOC;
}

export default HOC