import React, { useEffect } from 'react'
import localStorage from 'localStorage'
import { PictureAsPdfRounded, UploadFile } from '@mui/icons-material'

import LoadingModal from 'components/LoadingModal'
import PaginatedTable from 'components/PaginatedTable'
import EmptyState from 'components/EmptyState'

import KPISubmission from './submission'

import KIPHOC from './actions'
import { badgeStatus } from 'utils/stageStatus'
import { getTranslation } from 'utils/renderLanguage'

const language = localStorage.getItem( 'english' )
const columnData = [
  { header: getTranslation( language, 'No.' ), renderColumn: ( data, index ) => index + 1, containerStyle: { width: '30%' } },
  { header: getTranslation( language, 'Key Performance Indicator(KPI)' ), accessor: 'kpi_title', containerStyle: { width: '80%' } },
  { header: getTranslation( language, 'Description' ), accessor: 'kpi_description', containerStyle: { width: '150%' },
    renderColumn: rowData => <p style={{ textAlign: 'justify' }}>{ rowData.kpi_description }</p>
  },
  { header: getTranslation( language, 'Support Document' ), containerStyle: { width: '50%' },
    renderColumn: rowData => {
      if( rowData.kpi_document_url ){
        return(
          <button className='btn' onClick={ () => window.open( rowData.kpi_document_url, '_blank' ) }>
            <PictureAsPdfRounded style={{ color: '#cb1515' }}/>
          </button>
        )
      } else {
        return '-'
      }
    }
  },
  { header: getTranslation( language, 'Submission Date' ), accessor: 'kpi_submission_date', containerStyle: { width: '50%' } },
  { header: getTranslation( language, 'Notes' ), accessor: 'kpi_submission_note', containerStyle: { width: '80%' } },
  { header: getTranslation( language, 'Status' ), containerStyle: { width: '50%' }, 
    renderColumn: rowData => <p className='badge__status' style={ badgeStatus( rowData.kpi_status ) }>{ getTranslation( language, rowData.kpi_status ) }</p>
  },
]

const KPI = props => {

  useEffect( () => {
    props.getKPIs( props.projectDetail.kpi_indication )
  }, [ props.projectDetail ])

  return(
    <>
      {
        props.kpi && props.kpi.length > 0 && (
          <>
            <label className='at-project-mngmt__form-label mb-2'>KPI</label>
            <PaginatedTable
              rowData={ props.kpi }
              columnData={ columnData }
              actionsColumn={[{
                name: 'Submission',
                renderActionIcon: <UploadFile />,
                onShow: rowData => [ 'Pending', 'On-Progress' ].indexOf( rowData.kpi_status ) > -1,
                onClick: rowData => Promise.all([
                  props.onChangeKPIHOC( rowData, 'selectedKPI' )
                ]).then( () => {
                  props.onChangeKPIHOC( true, 'showSubmissionModal' )
                })
              }]}
            />
          </>
        )
      }
      {
        !( props.kpi && props.kpi.length > 0 ) && <EmptyState 
          title={ 'No KPI define' }
          description={ 'Please do inform admin to create KPI for your project' } />
      }
      {
        props.showSubmissionModal && <KPISubmission
          title={ getTranslation( language, 'KPI Submission' ) }
          selectedKPI={ props.selectedKPI }
          projectDetail={ props.projectDetail }
          putKPISubmission={ props.putKPISubmission }
          setOpen={ val => props.onChangeKPIHOC( val, 'showSubmissionModal' ) }  
        />
      }
      { props.onLoadKPI && <LoadingModal /> }
    </>
  )
}

export default KIPHOC( KPI )