import React, { Component } from 'react'
import _ from 'lodash'

import CustomSnackbar from 'components/Snackbar';

import { Get, Post, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    state = {
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      projectList: [],
      projectStatus: {},
      projectStatusGraph: {},
      onLoadProject: false,

      userRole: {},
      userRoleGraph: {},
      userStatus: {},
      userStatusGraph: {},
      onLoadUser: false,
      
    }

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
        callback && callback()
      },
      snackbarMessage: success
    })
    onChangeProjectManagementHOC = ( val, context ) => this.setState({ [ context ]: val })

    getAllProjects = () => Get(
      `/statistic-getProject`,
      this.getAllProjectsSuccess,
      this.getAllProjectsError,
      param => this.setState({ onLoadProject: param })
    )
    getAllProjectsSuccess = payload => {
      let temp = _.cloneDeep( payload.data.projectStatus )
      let labels = []
      let dataValue = []
      Object.keys( temp ).map( key => {
        if( key !== 'total_project' ){
          labels.push( key.substring( 0, key.indexOf( '_' ) ) )
          dataValue.push( temp[ key ] )
        }
      })

      this.setState({ 
        projectList: payload.data.projects,
        projectStatus: payload.data.projectStatus,
        projectStatusGraph: {
          labels: labels,
          datasets: [
            {
              label: 'Number of Project',
              data: dataValue,
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
              ],
              borderWidth: 2,
            },
          ]
        }
      })
    }
    getAllProjectsError = error => this.requestError( error )

    getProjects = projects => Get(
      `/statistic-getMultipleProject?projects=${ JSON.stringify( projects ) }`,
      this.getProjectsSuccess,
      this.getProjectsError,
      param => this.setState({ onLoadProject: param })
    )
    getProjectsSuccess = payload => {
      let temp = _.cloneDeep( payload.data.projectStatus )
      let labels = []
      let dataValue = []
      Object.keys( temp ).map( key => {
        if( key !== 'total_project' ){
          labels.push( key.substring( 0, key.indexOf( '_' ) ) )
          dataValue.push( temp[ key ] )
        }
      })

      this.setState({ 
        projectList: payload.data.projects,
        projectStatus: payload.data.projectStatus,
        projectStatusGraph: {
          labels: labels,
          datasets: [
            {
              label: 'Number of Project',
              data: dataValue,
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
              ],
              borderWidth: 2,
            },
          ]
        }
      })
    }
    getProjectsError = error => this.requestError( error )

    getUserStatistic = () => Get(
      '/statistic-getUserStatistic',
      this.getUserStatisticSuccess,
      this.getUserStatisticError,
      param => this.setState({ onLoadUser: param })
    )
    getUserStatisticSuccess = payload => {
      let roleLabels = []
      let roleDataValue = []
      Object.keys( payload.data.roleData ).map( key => {
        if( key !== 'total_user' ){
          roleLabels.push( key )
          roleDataValue.push( payload.data.roleData[ key ] )
        }
      })

      let statusLabels = []
      let statusDataValue = []
      Object.keys( payload.data.statusData ).map( key => {
        if( key !== 'total_user' ){
          statusLabels.push( key )
          statusDataValue.push( payload.data.statusData[ key ] )
        }
      })

      this.setState({ 
        userRole: payload.data.roleData,
        userRoleGraph: {
          labels: roleLabels,
          datasets: [
            {
              label: 'Number of User',
              data: roleDataValue,
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
            }
          ]
        },
        userStatus: payload.data.statusData,
        userStatusGraph: {
          labels: statusLabels,
          datasets: [
            {
              label: 'Number of User',
              data: statusDataValue,
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
            }
          ]
        },
      })
    }
    getUserStatisticError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props } 
            { ...this.state }
            onLoadProjectManagement={ this.state.loading }

            getProjects={ this.getProjects }
            getAllProjects={ this.getAllProjects }
            getUserStatistic={ this.getUserStatistic }
            onChangeProjectManagementHOC={ this.onChangeProjectManagementHOC }/>
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType }
          />
        </>
      )
    }
  }
  return WithHOC;
}

export default HOC