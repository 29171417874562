import React, { Component } from 'react'
import Moment from 'moment';

import CustomSnackbar from 'components/Snackbar';

import { Get, Post, Delete } from 'utils/axios'

const yearsBack = (back) => {
  const year = new Date().getFullYear();
  return Array.from({ length: back }, (v, i) => year - back + i + 1);
}

const yearsFront = (front) => {
  const year = new Date().getFullYear();
  return Array.from({ length: front }, (v, i) => year + i + 1);
}

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    state = {
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},
      searchParams: [
        { label: 'Funder', value: 'funder', param: '' },
        { label: 'Program', value: 'project_name', param: '' },
        { label: 'Implementing Agency', value: 'implementing_agency', param: '' },
      ],

      loading: false,
      dataValue: {
        total_project: 0,
        active_project: 0,
        pending_project: 0,
        rejected_project: 0,
      },
      projectList: [],
      searchProjectList: [],
      selectedProject: {},
      yearSelection: [
        ...yearsBack( 5 ), 
        ...yearsFront( 5 )
      ],
      selected_year: Moment().year().toString(),
      
      showEditModal: false,
      showDeleteModal: false,
      showCreateModal: false,
    }

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
        callback && callback()
      },
      snackbarMessage: success
    })
    onChangeProjectManagementHOC = ( val, context ) => this.setState({ [ context ]: val })

    getProjects = id => Get(
      `/project-getMultipleProject?id=${ id }`,
      this.getProjectsSuccess,
      this.getProjectsError,
      this.load
    )
    getProjectsSuccess = payload => this.setState({ 
      projectList: payload.data.projects,
      searchProjectList: payload.data.projects,
      dataValue: payload.data.summary
    })
    getProjectsError = error => this.requestError( error )

    createNewProject = ( dataToSubmit, onRefreshUser ) => Post(
      `/project-newProject`,
      dataToSubmit,
      () => this.createNewProjectSuccess( onRefreshUser ),
      this.createNewProjectError,
      this.load
    )
    createNewProjectSuccess = onRefreshUser => {
      this.setState({ showCreateModal: false })
      onRefreshUser()
      this.requestSuccess( 'New Project Created' )
    }
    createNewProjectError = error => this.requestError( error )

    updateProject = ( dataToSubmit, onRefreshUser ) => Post(
      `/project-updateProject`,
      dataToSubmit,
      () => this.updateProjectSuccess( onRefreshUser ),
      this.updateProjectError,
      this.load
    )
    updateProjectSuccess = onRefreshUser => {
      this.setState({ showEditModal: false })
      onRefreshUser()
      this.requestSuccess( 'Project Detail Updated' )
    }
    updateProjectError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props } 
            { ...this.state }
            onLoadProjectManagement={ this.state.loading }

            getProjects={ this.getProjects }
            updateProject={ this.updateProject }
            createNewProject={ this.createNewProject}
            onChangeProjectManagementHOC={ this.onChangeProjectManagementHOC }/>
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType }
          />
        </>
      )
    }
  }
  return WithHOC;
}

export default HOC