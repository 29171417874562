import React, { Component } from 'react'
import Moment from 'moment'
import _ from 'lodash'
import CustomSnackbar from 'components/Snackbar';

import { Get, Post, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    state = {
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      loading: false,
      showEditModal: false,
      showCreateModal: false,
      showSubmissionModal: false,
      showDeleteConfirmation: false,

      overallAssessmentList: [],
      this_month_submitted: false,
      selectedAssessment: {},
      showViewModal: false,
    }

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
        callback && callback()
      },
      snackbarMessage: success
    })
    onChangeOverallAssessmentHOC = ( val, context ) => this.setState({ [ context ]: val })

    getOverallAssessments = ( id, project_id ) => Get(
      `/assessment-getOverallAssessments?id=${ id }&project_id=${ project_id }`,
      this.getOverallAssessmentSuccess,
      this.getOverallAssessmentError,
      this.load
    )
    getOverallAssessmentSuccess = payload => {
      let this_month_submitted = false
      let temp = _.cloneDeep( payload.data.overall_assessment )
      temp.map( item => {
        if( item.date === `Q${ Moment().format( 'Q, yyyy' ) }` ){
          this_month_submitted = true
        }
      })
      this.setState({ 
        overallAssessmentList: payload.data.overall_assessment,
        this_month_submitted: this_month_submitted,
      })
    }
    getOverallAssessmentError = error => this.requestError( error )

    getAllOverallAssessment = project_id => Get(
      `/assessment-getOverallAssessments?project_id=${ project_id }`,
      this.getOverallAssessmentSuccess,
      this.getOverallAssessmentError,
      this.load
    )
    
    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props } 
            { ...this.state }
            onLoadSiteAssessment={ this.state.loading }

            getOverallAssessments={ this.getOverallAssessments }
            getAllOverallAssessment={ this.getAllOverallAssessment }
            onChangeOverallAssessmentHOC={ this.onChangeOverallAssessmentHOC }/>
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType }
          />
        </>
      )
    }
  }
  return WithHOC;
}

export default HOC