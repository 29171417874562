import React from 'react'
import localStorage from 'localStorage'
import { getTranslation } from 'utils/renderLanguage'
import './index.scss'

const Tabs = ({
  selections,
  selectedItem,
  onSelect
}) => {

  const language = localStorage.getItem( 'english' )

  return(
    <div style={{ paddingLeft: 20, marginTop: 10 }}>
      {
        selections.map( item => (
          <button 
            className={ item === selectedItem ? 'at-tab__selected' : 'at-tab__item' }
            onClick={ () => onSelect( item )}>
            { getTranslation( language, item ) }
          </button>
        ))
      }
    </div>
  )
}

export default Tabs;