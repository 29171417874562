import React, { Component } from 'react'
import localStorage from 'localStorage'

import CustomSnackbar from 'components/Snackbar';

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    state = {
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      loading: false,
      userDictionary: {},
      projectDictionary: []
    }

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
        callback && callback()
      },
      snackbarMessage: success
    })

    getUserDictionary = () => Get(
      `/getUserDictionary`,
      this.getUserDictionarySuccess,
      this.getUserDictionaryError,
      this.load
    )
    getUserDictionarySuccess = payload => this.setState({ userDictionary: payload.data })
    getUserDictionaryError = error => this.requestError( error )

    getProjectDictionary = () => Get(
      '/project-projectDictionary',
      this.getProjectDictionarySuccess,
      this.getProjectDictionaryError,
      this.load
    )
    getProjectDictionarySuccess = payload => this.setState({ projectDictionary: payload.data })
    getProjectDictionaryError = error => this.requestError( error )

    getUser = ( uid ) => Get(
      `/getUser?uid=${ uid }`,
      // `/getUser?uid=GVTqkAtx9gbZ4XOgQiKHzz9clvp2`,
      this.getUserSuccess,
      this.getUserError,
      this.load
    )
    getUserSuccess = payload => {
      this.requestSuccess( 'Login Success.' )
      localStorage.setItem( 'userLogin', JSON.stringify( payload.data ) )
    }
    getUserError = error => this.requestError( error.message )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props } 
            { ...this.state }
            onLoadDashboard={ this.state.loading }
            getUser={ this.getUser }
            getUserDictionary={ this.getUserDictionary }
            getProjectDictionary={ this.getProjectDictionary }
          />
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType }
          />
        </>
      )
    }
  }
  return WithHOC;
}

export default HOC