import React, { Component } from 'react'
import _ from 'lodash'
import Moment from 'moment'

import CustomSnackbar from 'components/Snackbar';

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    state = {
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      loading: false,
      assessmentList: [],
      this_month_submitted: false,
    }

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
        callback && callback()
      },
      snackbarMessage: success
    })
    onChangeProjectAssessmentHOC = ( val, context ) => this.setState({ [ context ]: val })

    getProjectAssessment = ( project_id, id ) => Get(
      `/assessment-getAssessments?project_id=${ project_id }&${ id ? `id=${ id }` : '' }`,
      payload => this.getProjectAssessmentSuccess(payload, project_id, id),
      this.getProjectAssessmentError,
      this.load
    ) 
    getProjectAssessmentSuccess = (payload, project_id, id) => {
      let this_month_submitted = false
      let temp = _.cloneDeep( payload.data.assessments )
      temp = temp.map( item => {
        if( item.date === `Q${ Moment( this.state.selectedYear ).format( 'Q, yyyy' ) }` ){
          this_month_submitted = true
        }

        return {
          ...item,
          ...item[ 6 ]
        }
      })
      this.setState({ 
        assessmentList: _.filter( temp, item => item.project_id === project_id && item.assessor === id ),
        this_month_submitted: this_month_submitted
      })
    }
    getProjectAssessmentError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props } 
            { ...this.state }
            onLoadProjectAssessment={ this.state.loading }

            getProjectAssessment={ this.getProjectAssessment }
            onChangeProjectAssessmentHOC={ this.onChangeProjectAssessmentHOC }/>
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType }
          />
        </>
      )
    }
  }
  return WithHOC;
}

export default HOC