import React, { Component } from 'react'
import _ from 'lodash'
import Moment from 'moment'

import CustomSnackbar from 'components/Snackbar';

import { Get, Post, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    state = {
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      loading: false,
      showEditModal: false,
      showCreateModal: false,
      showDeleteConfirmation: false,

      stage_and_milestone: [],
      activity_reports: [],
      this_month_submitted: false,
      this_quarter_assessment: false,
      selectedActivityReport: {},
      activity_reports_summary: {},
      summary_year_options: [],
      selected_year: Moment().format( 'YYYY' )
    }

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
        callback && callback()
      },
      snackbarMessage: success
    })
    onChangeActivityReportHOC = ( val, context ) => this.setState({ [ context ]: val })

    getProjectPerformance = id => Get(
      `/project-getProject?project_id=${ id }`,
      this.getProjectPerformanceSuccess,
      this.getProjectPerformanceError,
      this.load
    ) 
    getProjectPerformanceSuccess = payload => {
      let temp = []
      payload.data?.stage_and_milestone?.map((stage, stageIndex) => {
        stage.stage_milestone.map((milestone, milestoneIndex) => {
          temp.push({
            ...milestone,
            milestone_index: milestoneIndex,
            stage_index: stageIndex,
            stage_name: stage.stage_name,
            stage_status: stage.stage_status,
            stage_timeline: stage.stage_timeline
          })
        })
      })
      this.setState({ stage_and_milestone: temp || [] })
    }
    getProjectPerformanceError = error => this.requestError( error )

    getActivityReports = reports => Get(
      `/activity_report-getActivityReports?reports=${ JSON.stringify( reports ) }&project_id=${ this.props.projectDetail.project_id }`,
      this.getActivityReportsSuccess,
      this.getActivityReportsError,
      this.load
    )
    getActivityReportsSuccess = payload => {
      let temp = _.cloneDeep( payload.data.reports )
      let yearOptions = ['2018', '2019', '2020', '2021', '2022', '2023', '2024']
      let summary = {}
      temp.map( report => {
        let month = Moment( report.submited_date ).format( 'MMM' )
        let year = Moment( report.submited_date ).format( 'YYYY' )
        if( yearOptions.includes( year ) ){
          if( summary[ year ] ){
            summary[ year ][ month ] = report.activity_report_file_url
          } else {
            summary[ year ] = {}
            summary[ year ][ month ] = report.activity_report_file_url
          }
        } else {
          yearOptions.push( year )
          summary = {
            [ year ]:{
              [ month ]: report.activity_report_file_url
            } 
          }
        }
      })

      this.setState({ 
        activity_reports: payload.data.reports,
        activity_reports_summary: summary,
        summary_year_options: yearOptions,
        this_month_submitted: payload.data.this_month_submitted,
        this_quarter_assessment: payload.data.this_quarter_assessment
      })
    }
    getActivityReportsError = error => this.requestError( error )

    createUtilization = dataToSubmit => Post(
      `/financial-newUtilization`,
      dataToSubmit,
      this.createUtilizationSuccess,
      this.createUtilizationError,
      this.load
    )
    createUtilizationSuccess = payload => {
      this.requestSuccess( payload.data.message )
      this.setState({ showCreateUtilizationModal: false })
      this.props.getProjectDetail( this.props.projectDetail.project_id )
    }
    createUtilizationError = error => this.requestError( error )

    createActivityReport = dataToSubmit => Post(
      `/activity_report-newActivityReport`,
      dataToSubmit,
      this.createActivityReportSuccess,
      this.createActivityReportError,
      this.load
    )
    createActivityReportSuccess = payload => {
      this.requestSuccess( payload.data.message )
      this.setState({ showCreateModal: false })
      this.props.getProjectDetail( this.props.projectDetail.project_id )

      if( !this.state.this_quarter_assessment ){
        this.createAssessment()
      }
    }
    createActivityReportError = error => this.requestError( error )

    createAssessment = () => Post(
      `/assessment-newAssessment`,
      { project_id: this.props.projectDetail.project_id },
      () => {},
      () => {},
      this.load
    )

    updateActivityReport = dataToSubmit => Post(
      '/activity_report-updateActivityReports',
      dataToSubmit,
      this.updateActivityReportSuccess,
      this.updateActivityReportError,
      this.load
    )
    updateActivityReportSuccess = payload => {
      this.requestSuccess( payload.data.message )
      this.setState({ showEditModal: false })
      this.props.getProjectDetail( this.props.projectDetail.project_id )
    }
    updateActivityReportError = error => this.requestError( error )

    updateProjectPerformance = dataToSubmit => Post(
      `/project-updateProjectPerformance`,
      dataToSubmit,
      this.updateProjectPerformanceSuccess,
      this.updateProjectPerformanceError,
      this.load
    )
    updateProjectPerformanceSuccess = payload => {
      this.requestSuccess( 'Project Performance update recorded' )
    }
    updateProjectPerformanceError = error => this.requestError( error )

    deleteActivityReport = id => Delete (
      `/activity_report-deleteActivityReports?id=${ id }`,
      this.deleteActivityReportSuccess,
      this.deleteActivityReportError,
      this.load
    )
    deleteActivityReportSuccess = payload => {
      this.requestSuccess( payload.data.message )
      this.setState({ showDeleteConfirmation: false })
      this.props.getProjectDetail( this.props.projectDetail.project_id )
    }
    deleteActivityReportError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props } 
            { ...this.state }
            onLoadActivityReport={ this.state.loading }
            
            createUtilization={ this.createUtilization }
            getActivityReports={ this.getActivityReports }
            updateActivityReport={ this.updateActivityReport }
            createActivityReport={ this.createActivityReport }
            deleteActivityReport={ this.deleteActivityReport }
            getProjectPerformance={ this.getProjectPerformance }
            updateProjectPerformance={ this.updateProjectPerformance }
            onChangeActivityReportHOC={ this.onChangeActivityReportHOC }/>
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType }
          />
        </>
      )
    }
  }
  return WithHOC;
}

export default HOC