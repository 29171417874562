import React, { Component } from 'react'

import CustomSnackbar from 'components/Snackbar';

import { Delete, Get, Post } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    state = {
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      loading: false,
      userList: [],
      dataValue: {
        total_user: 0,
        total_assessor: 0,
        total_secretariat: 0,
        total_leader: 0,
      },

      selectedUser: {},
      
      showEditModal: false,
      showCreateModal: false,
      showDeleteModal: false
    }

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
        callback && callback()
      },
      snackbarMessage: success
    })
    onChangeUserManagementHOC = ( val, context ) => this.setState({ [ context ]: val })

    getUsers = () => Get(
      `/getUser`,
      this.getUsersSuccess,
      this.getUsersError,
      this.load
    )
    getUsersSuccess = payload => this.setState({
      dataValue: payload.data.summary,
      userList: payload.data.user
    })
    getUsersError = error => this.requestError( error )

    createNewUser = dataToSubmit => Post(
      `/newUser`,
      {
        ...dataToSubmit,
        project: []
      },
      this.createNewUserSuccess,
      this.createNewUserError,
      this.load
    )
    createNewUserSuccess = payload => {
      this.setState({ showCreateModal: false })
      this.getUsers()
      this.requestSuccess( 'New User Created' )
    }
    createNewUserError = error => this.requestError( error )

    updateUser = dataToSubmit => Post(
      `/updateUser`,
      dataToSubmit,
      this.updateUserSuccess,
      this.updateUserError,
      this.load
    )
    updateUserSuccess = payload => {
      this.setState({ showEditModal: false })
      this.getUsers()
      this.requestSuccess( 'User Details Updated' )
    }
    updateUserError = error => this.requestError( error )

    deleteUser = id => Delete(
      `/deleteUser?uid=${ id }`,
      this.deleteUserSuccess,
      this.deleteUserError,
      this.load
    )
    deleteUserSuccess = payload => {
      console.log( payload.data )
      this.setState({ showDeleteModal: false })
      this.getUsers()
      this.requestSuccess( 'User Deleted' )
    }
    deleteUserError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props } 
            { ...this.state }
            onLoadUserManagement={ this.state.loading }

            getUsers={ this.getUsers }
            updateUser={ this.updateUser }
            deleteUser={ this.deleteUser }
            createNewUser={ this.createNewUser }
            onChangeUserManagementHOC={ this.onChangeUserManagementHOC }/>
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType }
          />
        </>
      )
    }
  }
  return WithHOC;
}

export default HOC