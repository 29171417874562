import React, { useEffect, useState } from 'react'
import localStorage from 'localStorage'
import { EditOutlined, DeleteForeverRounded, PictureAsPdfRounded } from '@mui/icons-material'

import Tab from 'components/Tab'
import EmptyState from 'components/EmptyState'
import LoadingModal from 'components/LoadingModal'
import PaginatedTable from 'components/PaginatedTable'
import DeleteConfirmation from 'components/Card/Confirmation'

import EditUtilization from './utilization'
import CreateUtilization from './utilization'
import EditDisbursement from './disbursement'

import FinancialHOC from './actions'
import { getTranslation } from 'utils/renderLanguage'

const language = localStorage.getItem( 'english' )
const disbursementColumn = [
  { header: getTranslation( language, 'Date of Disbursement' ), accessor: 'disbursement_date', containerStyle: { width: '50%' } },
  { header: getTranslation( language, 'Disbursement' ), accessor: 'disbursement_title', containerStyle: { width: '80%' } },
  { header: getTranslation( language, 'Funding' ), accessor: 'funding', containerStyle: { width: '50%' } },
  { header: getTranslation( language, 'Amount' ), accessor: 'amount', containerStyle: { width: '50%' } },
  { header: getTranslation( language, 'Project Completion(%)' ), accessor: 'project_completion', containerStyle: { width: '50%' } },
  { header: getTranslation( language, 'Support Document' ), containerStyle: { width: '50%' },
    renderColumn: rowData => (
      <button className='btn' onClick={ () => window.open( rowData.disbursement_file_url, '_blank' ) }>
        {
          rowData.disbursement_file_url ? <PictureAsPdfRounded style={{ color: '#cb1515' }}/> : '-'
        }
      </button>
    )
  },
]

const utilizationColumn = [
  { header: getTranslation( language, 'Date' ), accessor: 'utilication_date' },
  { header: getTranslation( language, 'Utilization(RM)' ), accessor: 'utilization_amount' },
  { header: getTranslation( language, 'Utilization(%)' ), accessor: 'utilization_percentage' },
  { header: getTranslation( language, 'Project Completion(%)' ), accessor: 'project_completion' },
  { header: getTranslation( language, 'Support Document' ),
    renderColumn: rowData => (
      <button className='btn' onClick={ () => window.open( rowData.utilization_file_url, '_blank' ) }>
        {
          rowData.utilization_file_url ? <PictureAsPdfRounded style={{ color: '#cb1515' }}/> : '-'
        }
      </button>
    )
  },
]

const Financial = props => {

  const [ selectedTab, setSelectedTab ] = useState( 'Disbursement Progress' )

  useEffect( () => {
    Promise.all([
      props.getDisbursements( props.projectDetail.disbursement_progress )
    ]).then( () => {
      props.getUtilizations( props.projectDetail.utilization_progress )
    })
  }, [ props.projectDetail ])

  return(
    <>
      <Tab
        selections={ [ 'Disbursement Progress', 'Utilization Progress' ] }
        selectedItem={ selectedTab }
        onSelect={ setSelectedTab }
      />
      <hr/>
      {
        selectedTab === 'Disbursement Progress' && (
          <div className='mx-4'>
            {
              props.disbursement && props.disbursement.length > 0 && (
                <>
                  <label className='at-project-mngmt__form-label mb-2'>{ getTranslation( language, 'Disbursement Progress' ) }</label>
                  <PaginatedTable
                    rowData={ props.disbursement }
                    columnData={ disbursementColumn }
                    actionsColumn={[{
                      name: 'Edit',
                      renderActionIcon: <EditOutlined />,
                      onClick: rowData => Promise.all([
                        props.onChangeFinancialHOC( rowData, 'selectedDisbursement' )
                      ]).then( () => {
                        props.onChangeFinancialHOC( true, 'showEditDisbursementModal' )
                      })
                    }]}
                  />
                </>
              )
            }
            {
              !( props.disbursement && props.disbursement.length > 0 ) && <EmptyState
                title={ 'No Disbursment define' }
                description={ 'Please do inform admin to create Disbursement for your project' } />
            }
          </div>
        )
      }
      {
        selectedTab === 'Utilization Progress' && (
          <div className='mx-4'>
            <div className='d-flex justify-content-between mb-2'>
              <label className='at-project-mngmt__form-label'>{ getTranslation( language, 'Utilization Progress' ) }</label>
              <button
                className="btn btn-primary"
                style={{ width: 300 }}
                onClick={ () => props.onChangeFinancialHOC( true, 'showCreateUtilizationModal' ) }>
                { getTranslation( language, 'Create New Utilization' ) }
              </button>
            </div>
            <PaginatedTable
              rowData={ props.utilization }
              columnData={ utilizationColumn }
              actionsColumn={[{
                name: 'Edit',
                renderActionIcon: <EditOutlined />,
                onClick: rowData => Promise.all([
                  props.onChangeFinancialHOC( rowData, 'selectedUtilization' )
                ]).then( () => {
                  props.onChangeFinancialHOC( true, 'showEditUtilizationModal' )
                })
              }, {
                name: 'Delete',
                style: { background: '#cb1515' },
                renderActionIcon: <DeleteForeverRounded />,
                onClick: rowData => Promise.all([
                  props.onChangeFinancialHOC( rowData, 'selectedUtilization' )
                ]).then( () => {
                  props.onChangeFinancialHOC( true, 'showDeleteUtilizationModal' )
                })
              }]}
            />
          </div>
        )
      }
      {
        props.showCreateUtilizationModal && <CreateUtilization
          mode={ 'create' }
          title={ getTranslation( language, 'Create New Utilization' ) }
          projectDetail={ props.projectDetail }
          createUtilization={ props.createUtilization }
          setOpen={ val => props.onChangeFinancialHOC( val, 'showCreateUtilizationModal' ) }  
        />
      }
      {
        props.showEditDisbursementModal && <EditDisbursement
          mode={ 'update' }
          title={ getTranslation( language, 'Edit Disbursement' ) }
          projectDetail={ props.projectDetail }
          updateDisbursement={ props.updateDisbursement }
          selectedDisbursement={ props.selectedDisbursement }
          setOpen={ val => props.onChangeFinancialHOC( val, 'showEditDisbursementModal' ) }  
        />
      }
      {
        props.showEditUtilizationModal && <EditUtilization
          mode={ 'update' }
          title={ getTranslation( language, 'Edit Utilization' ) }
          projectDetail={ props.projectDetail }
          updateUtilization={ props.updateUtilization }
          selectedUtilization={ props.selectedUtilization }
          setOpen={ val => props.onChangeFinancialHOC( val, 'showEditUtilizationModal' ) }  
        />
      }
      {
        props.showDeleteUtilizationModal && <DeleteConfirmation
          isOpen={ true }
          onClick={ () => props.deleteUtilization( props.selectedUtilization.utilization_id ) }
          confirmationText={
            <>
              You are going to remove the selected utilization record from the project?
              <br/><br/>
              <b className='text-danger'>This action is non-reversible.</b>
            </>
          }
          setOpen={ val => props.onChangeFinancialHOC( val, 'showDeleteUtilizationModal' ) }
        />
      }
      { props.onLoadFinancial && <LoadingModal /> }
    </>
  )
}

export default FinancialHOC( Financial )