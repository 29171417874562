import React, { useEffect, useState } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import localStorage from 'localStorage'

import Navbar from "components/SideNav"
import TopNav from "components/TopNav"
import LoadingModal from "components/LoadingModal"
import DashboardHOC from './actions'

const Dashboard = props => {

  const navigate = useNavigate()
  const language = localStorage.getItem( 'english' )
  const user = JSON.parse( localStorage.getItem( 'userLogin' ) )

  const [ loading, setLoading ] = useState( true )
  const [ sidebar, setSidebar ] = useState( true )

  useEffect( () => {
    if( user === null ){
      navigate( '/login' )
    } else {
      setLoading( false )
      props.getUser( user.uid )
      props.getUserDictionary()
      props.getProjectDictionary()
      switch( user.role ){
        case 'Super Admin':
          navigate( '/dashboard/admin/user-management' )
          break;
        case 'Secretariat':
          navigate( '/dashboard/assessor/project-management' )
          break
        case 'Assessor':
          navigate( '/dashboard/assessor/project-management' )
          break
        case 'Project Leader':
          navigate( '/dashboard/project-management' )
          break
        default:
          navigate( '/' )
          break
      }
    }
  }, [] )

  return (
    <div style={{ height: "100%" }}>
      {
        user && user !== null && (
          <>
            <TopNav
              sidebar={ sidebar }
              language={ language }
              setSidebar={ setSidebar }
              onNavigate={ navigate } />
            {
              user.role === 'Super Admin' && <Navbar sidebar={ sidebar } user={ user } language={ language }/>
            }
            <div className='module-container' style={{ marginLeft: user.role === 'Super Admin' ? 80 : 0 }}>
              <Outlet context={{
                user: user,
                language: language,
                onNavigate: navigate,
                ...props
              }}/>
            </div>
          </>
        )
      }
      { ( loading || props.onLoadDashboard ) && <LoadingModal /> }
    </div>
  )
}

export default DashboardHOC(Dashboard);
