import React, { Component } from 'react'

import CustomSnackbar from 'components/Snackbar';

import { Get, Post, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    state = {
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      loading: false,
      showEditModal: false,
      showCreateModal: false,
      showDeleteConfirmation: false,
      showApproveConfirmation: false,

      achievements: [],
      selectedAchievement: {}
    }

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
        callback && callback()
      },
      snackbarMessage: success
    })
    onChangeAchievementHOC = ( val, context ) => this.setState({ [ context ]: val })

    getAchievements = achievements => Get(
      `/achievement-getAchievements?achievements=${ JSON.stringify( achievements ) }`,
      this.getAchievementsSuccess,
      this.getAchievementsError,
      this.load
    )
    getAchievementsSuccess = payload => this.setState({ achievements: payload.data.achievement })
    getAchievementsError = error => this.requestError( error )

    createNewAchievement = dataToSubmit => Post(
      `/achievement-newAchievement`,
      dataToSubmit,
      this.createNewAchievementSuccess,
      this.createNewAchievementError,
      this.load
    )
    createNewAchievementSuccess = payload => {
      this.requestSuccess( payload.data.message )
      this.setState({ showCreateModal: false })
      this.props.getProjectDetail( this.props.projectDetail.project_id )
    }
    createNewAchievementError = error => this.requestError( error )
  
    updateAchievement = dataToSubmit => Post(
      `/achievement-updateAchievement`,
      dataToSubmit,
      this.updateAchievementSuccess,
      this.updateAchievementError,
      this.load
    )
    updateAchievementSuccess = payload => {
      this.requestSuccess( payload.data.message )
      this.setState({ 
        showEditModal: false,
        showApproveConfirmation: false,
      })
      this.props.getProjectDetail( this.props.projectDetail.project_id )
    }
    updateAchievementError = error => this.requestError( error )

    deleteAchievement = id => Delete (
      `/achievement-deleteAchievement?id=${ id }`,
      this.deleteAchievementSuccess,
      this.deleteAchievementError,
      this.load
    )
    deleteAchievementSuccess = payload => {
      this.requestSuccess( payload.data.message )
      this.setState({ showDeleteConfirmation: false })
      this.props.getProjectDetail( this.props.projectDetail.project_id )
    }
    deleteAchievementError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props } 
            { ...this.state }
            onLoadAchivement={ this.state.loading }

            getAchievements={ this.getAchievements }
            updateAchievement={ this.updateAchievement }
            deleteAchievement={ this.deleteAchievement }
            createNewAchievement={ this.createNewAchievement }
            onChangeAchievementHOC={ this.onChangeAchievementHOC }/>
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType }
          />
        </>
      )
    }
  }
  return WithHOC;
}

export default HOC