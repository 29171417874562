import React, { useEffect } from 'react'
import localStorage from 'localStorage'
import { Select, MenuItem } from '@mui/material'
import { EditOutlined, PictureAsPdfRounded, DeleteForeverRounded, FilePresent } from '@mui/icons-material'

import LoadingModal from 'components/LoadingModal'
import PaginatedTable from 'components/PaginatedTable'
import DeleteConfirmation from 'components/Card/Confirmation'

import CreateActivityReport from './form';
import EditActivityReport from './form';

import { getTranslation } from 'utils/renderLanguage'
import { badgeStatus } from 'utils/stageStatus'
import { monthList } from 'utils/dictionary'
import ActivityReportHOC from './actions'

const language = localStorage.getItem( 'english' )

const columnData = [
  { header: getTranslation( language, 'Submitted Date' ), accessor: 'submited_date', containerStyle: { width: '50%' } },
  { header: getTranslation( language, 'Title' ), containerStyle: { width: '80%' },
    renderColumn: rowData => (
      <div>
        <label>{rowData.title || getTranslation(language, 'No Title')}</label>
        <p>{ rowData.description || getTranslation(language, 'No Description') }</p>
      </div>
    )
  },
  { header: getTranslation( language, 'Estimate Completion(%)' ), accessor: 'completion_percentage', containerStyle: { width: '50%' } },
  { header: getTranslation( language, 'Link' ), containerStyle: { width: '200%' }, renderColumn: rowData => (
    <div className='row'>
      <div className='acitvity-report_link-item col-md-6'>
        <label>{ getTranslation( language, 'Video Folder Link' ) }</label>
        <a href={ rowData.video_folder_link } target={ '_blank' }>{ rowData.video_folder_link }</a>
      </div>
      <div className='acitvity-report_link-item col-md-6'>
        <label>{ getTranslation( language, 'Publication Folder Link' ) }</label>
        <a href={ rowData.publication_folder_link } target={ '_blank' }>{ rowData.publication_folder_link }</a>
      </div>
      <div className='acitvity-report_link-item col-md-6'>
        <label>{ getTranslation( language, 'Image Folder Link' ) }</label>
        <a href={ rowData.image_folder_link } target={ '_blank' }>{ rowData.image_folder_link }</a>
      </div>
      <div className='acitvity-report_link-item col-md-6'>
        <label>{ getTranslation( language, 'Other Folder Link' ) }</label>
        <a href={ rowData.others_folder_link } target={ '_blank' }>{ rowData.others_folder_link }</a>
      </div>
    </div>
  )},
  { header: getTranslation( language, 'Status' ), containerStyle: { width: '50%' }, renderColumn: rowData => (
    <p className='badge__status' style={{ width: 'fit-content', ...badgeStatus( rowData.status ) }}>
      { getTranslation( language, rowData.status ) }
    </p>
  )},
]

const ActivityReport = props => {

  useEffect( () => {
    props.getProjectPerformance (props.projectDetail.project_id )
    props.getActivityReports( props.projectDetail.activity_report )
  }, [ props.projectDetail ])

  return(
    <>
      <div className='d-flex justify-content-between mb-2'>
        <label className='at-project-mngmt__form-label'>{ getTranslation( language, 'Activity Report' ) }</label>
        <button
          className="btn btn-primary"
          style={{ width: 300 }}
          // disabled={ props.this_month_submitted }
          onClick={ () => props.onChangeActivityReportHOC( true, 'showCreateModal' ) }>
          { getTranslation( language, 'Create Activity Report' ) }
        </button>
      </div>
      {
        props.activity_reports.length > 0 && (
          <div className='activity-report_summary-card'>
            <div className='year-selection'>
              <label>{ getTranslation( language, 'Year' ) }</label>
              <Select
                fullWidth
                value={ props.selected_year || [] }
                style={{ borderRadius: `0.375rem`, borderColor: '#ced4da', height: 50, width: 190 }}
                onChange={ e => props.onChangeActivityReportHOC( e.target.value, 'selected_year' ) }
              >
                {
                  props.summary_year_options.map(( option, index ) => (
                    <MenuItem key={ index } value={ option } >{ option }</MenuItem>
                  ))
                }
              </Select>
            </div>
            <div className='activity-report_summary'>
              <ul>
                <li className='header'>{ getTranslation( language, 'Month' ) }</li>
                <li className='header'>{ getTranslation( language, 'Status' ) }</li>
                <li className='last-child header'>{ getTranslation( language, 'File Upload' ) }</li>
              </ul>
              {
                monthList.map( month => {
                  let isExist = props.activity_reports_summary[ props.selected_year ]
                    ? props.activity_reports_summary[ props.selected_year ][ month ]
                    : false
                  return(
                    <ul>
                      <li className='header'>{ month }</li>
                      <li className='status' style={{ background: isExist ? '#37d158' : '#ad1919' }}></li>
                      <li className='last-child'>
                        { 
                          isExist
                          ? <FilePresent style={{ cursor: 'pointer', color: '#00a1ff' }} onClick={ () => window.open( isExist, '_blank' ) } /> 
                          : '-'
                        }
                      </li>
                    </ul>
                  )
                })
              }
            </div>
          </div>
        )
      }
      <PaginatedTable
        rowData={ props.activity_reports }
        columnData={ columnData }
        actionsColumn={[{
          name: 'Activity Report',
          style: { background: '#565656' },
          renderActionIcon: <PictureAsPdfRounded />,
          onClick: rowData => window.open( rowData.activity_report_file_url, '_blank' )
        }, {
          name: 'Edit',
          renderActionIcon: <EditOutlined />,
          onClick: rowData => Promise.all([
            props.onChangeActivityReportHOC( rowData, 'selectedActivityReport' )
          ]).then( () => {
            props.onChangeActivityReportHOC( true, 'showEditModal' )
          })
        }, {
          name: 'Delete',
          style: { background: '#cb1515' },
          renderActionIcon: <DeleteForeverRounded />,
          onClick: rowData => Promise.all([
            props.onChangeActivityReportHOC( rowData, 'selectedActivityReport' )
          ]).then( () => {
            props.onChangeActivityReportHOC( true, 'showDeleteConfirmation' )
          })
        }]}
      />
      { 
        props.showCreateModal && <CreateActivityReport
          mode={ 'create' }
          title={ getTranslation( language, 'Create Activity Report' ) }
          projectDetail={ props.projectDetail }
          stage_and_milestone={ props.stage_and_milestone }
          createUtilization={ props.createUtilization }
          createActivityReport={ props.createActivityReport }
          updateProjectPerformance={ props.updateProjectPerformance }
          setOpen={ val => props.onChangeActivityReportHOC( val, 'showCreateModal' ) }  
        /> 
      }
      { 
        props.showEditModal && <EditActivityReport
          mode={ 'update' }
          title={ getTranslation( language, 'Edit Activity Report' ) }
          projectDetail={ props.projectDetail }
          stage_and_milestone={ props.stage_and_milestone }
          createUtilization={ props.createUtilization }
          updateActivityReport={ props.updateActivityReport }
          updateProjectPerformance={ props.updateProjectPerformance }
          selectedActivityReport={ props.selectedActivityReport }
          setOpen={ val => props.onChangeActivityReportHOC( val, 'showEditModal' ) }  
        />
      }
      {
        props.showDeleteConfirmation && <DeleteConfirmation
          isOpen={ true }
          onClick={ () => props.deleteActivityReport( props.selectedActivityReport.activity_report_id ) }
          confirmationText={
            <>
              You are going to remove the selected activity report from the project?
              <br/><br/>
              <b className='text-danger'>This action is non-reversible.</b>
            </>
          }
          setOpen={ val => props.onChangeActivityReportHOC( val, 'showDeleteConfirmation' ) }
        />
      }
      { props.onLoadActivityReport && <LoadingModal /> }
    </>
  )
}

export default ActivityReportHOC( ActivityReport );