import Moment from 'moment'
import _ from 'lodash'
import localStorage from 'localStorage'
import React, { useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';

import EmptyState from 'components/EmptyState';
import LoadingModal from 'components/LoadingModal';
import SubmissionModal from './form'

import { renderBackground } from 'utils/stageStatus'
import { getTranslation } from 'utils/renderLanguage'
import { milestoneFieldData, milestonStatus } from "assets/ProjectManagement";

import ProjectPerformanceHOC from './actions'
import './index.scss'

const language = localStorage.getItem( 'english' )

const ProjectPerformance = props => {

  let accumulated_kpi = 0
  const { stage_and_milestone } = props.projectObj
  const [ milestoneList, setMilestoneList ] = useState([])

  useEffect( () => {
    Promise.all([
      props.getProjectPerformance( props.projectDetail.project_id )
    ]).then( () => {
      props.getDisbursementDictionary( props.projectDetail.disbursement_progress )
    })
  }, [ props.projectDetail ] )

  useEffect( () => {
    let temp = []
    props.projectObj?.stage_and_milestone?.map((stage, stageIndex) => {
      stage.stage_milestone.map((milestone, milestoneIndex) => {
        temp.push({
          ...milestone,
          milestone_index: milestoneIndex,
          stage_index: stageIndex,
          stage_name: stage.stage_name,
          stage_status: stage.stage_status,
          stage_timeline: stage.stage_timeline
        })
      })
    })
    setMilestoneList( temp || [] )
  }, [props.projectObj] )

  // Changes due to moving the update project performance to Activity Reports
  // const disabledCheck = rowData => {
  //   if( new Date().getDate() <= 10 ) {
  //     if( rowData.updated_at ){
  //       let updatedDate = new Date( rowData.updated_at )
  //       if( updatedDate.getMonth() === new Date().getMonth() ){
  //         return false
  //       } else {
  //         return true
  //       }
  //     } else {
  //       return false
  //     }
  //   } else {
  //     return true
  //   }
  // }

  return(
    <>
      {
         ( stage_and_milestone && stage_and_milestone.length > 0 ) && (
          <>
            <div className='project-detail__milestone-item-content project-performance-overall' >
              <p style={{ fontWeight: 700, fontSize: 14, marginBottom: 0 }}>
                Overall KPI: {milestoneList.reduce((acc, milestone) => acc + parseFloat(milestone.milstone_actual_kpi || 0), 0)}% / 100%
              </p>
            </div>
            <div className='project-performance__timeline-cont'>
              <label>{ getTranslation( language, 'Project Timeline' ) }</label>
              <div className='d-flex mt-1'>
                {
                  stage_and_milestone.map(( stage, index ) => {
                    return(
                      <Tooltip placement="top" title={ getTranslation( language, stage.stage_status ) || '-' }>
                        <span
                          key={ index }
                          style={{ background: renderBackground( stage.stage_status ) }}
                          className={ 'project-performance__timeline' }>
                          { stage.stage_name }
                          <p>{ stage.stage_timeline ? `Q${ Moment( stage.stage_timeline ).format( 'Q, yyyy' ) }` : '-' }</p>
                        </span>
                      </Tooltip>
                    )
                  })
                }
              </div>
            </div>
            <div className="project-performance-detail-cont">
              {
                stage_and_milestone.map(( stage, stageIndex ) => {
                  return(
                    <div key={ stageIndex } className={ 'mb-4' }>
                      <label style={{ fontSize: 16, color: 'black', marginBottom: 5 }}>{ stageIndex + 1 }: { stage.stage_name }</label>
                      {
                        stage.stage_milestone && stage.stage_milestone.length > 0 && (
                          <div className='project-detail__milestone-cont'>
                            {
                              stage.stage_milestone.map(( milestone, milestoneIndex ) => {
                                accumulated_kpi = accumulated_kpi + Number( milestone.milstone_actual_kpi || 0 )
                                return(
                                  <div key={ milestoneIndex } className={ 'project-detail__milestone-item' }>
                                    {
                                      milestoneFieldData.map(( milestoneField, milestoneFieldIndex ) => {
                                        return(
                                          <div
                                            key={ milestoneFieldIndex }
                                            style={{ ...milestoneField.containerStyle }}
                                            className='project-detail__milestone-item-content' >
                                            {
                                              milestoneIndex === 0 && (
                                                <label>{ milestoneField.label }</label>
                                              )
                                            }
                                            {
                                              milestoneField.value === 'milestone_fund_utilization' &&
                                                <p style={{ fontWeight: 500, fontSize: 14 }}>
                                                  {
                                                     _.find( props.disbursementList, { value: milestone[ milestoneField.value ]  } )
                                                      ? _.find( props.disbursementList, { value: milestone[ milestoneField.value ]  } ).label
                                                      : '-' 
                                                  }
                                                </p>
                                            }
                                            {
                                              milestoneField.value === 'milestone_status' &&
                                                <Tooltip placement="top" title={ getTranslation( language, milestone[ milestoneField.value ] ) || '-' }>
                                                  <p
                                                    style={{ backgroundColor: renderBackground( milestone[ milestoneField.value ] ) }}
                                                    className='project-detail__milestone-status'>
                                                  </p>
                                                </Tooltip>
                                            }
                                            {
                                              milestoneField.value.includes( 'date' ) &&
                                                <p style={{ fontWeight: 500, fontSize: 14 }}>{
                                                  milestone[ milestoneField.value ]
                                                  ? Moment( milestone[ milestoneField.value ] ).format( 'MMM YYYY' )
                                                  : '-'
                                                }</p>
                                            }
                                            {
                                              milestoneField.value === 'accumulated_kpi' &&
                                                <p style={{ fontWeight: 500, fontSize: 14 }}>{accumulated_kpi}%</p>
                                            }
                                            {
                                              [ 'milestone_title', 'milstone_kpi', 'milstone_actual_kpi' ].includes( milestoneField.value ) &&
                                                <p style={{ fontWeight: 500, fontSize: 14, textAlign: milestoneField.value === 'milestone_title' ? 'justify' : 'center' }}>{ milestone[ milestoneField.value ] || '-' }</p>
                                            }
                                          </div>
                                        )
                                      })
                                    }
                                    {/* <CustomButton
                                      content={ ( milestone.milestone_actual_end_date || milestone.milestone_actual_start_date || milestone.milstone_actual_kpi )
                                        ? <ModeEdit />
                                        : <UploadFile />
                                      }
                                      containerClass={ 'd-flex' }
                                      conatinerStyle={{ width: 'fit-content' }}
                                      // disabled={ disabledCheck( milestone ) }
                                      tooltipContent={ getTranslation( language, 'Edit Completion Record' ) }
                                      onClick={ () => Promise.all([
                                          props.onChangePerformanceHOC( milestoneIndex, 'selectedMilestoneIndex' ),
                                          props.onChangePerformanceHOC( stageIndex, 'selectedStageIndex' )
                                        ]).then( () => {
                                          props.onChangePerformanceHOC( true, 'showSubmissionModal' )
                                        })
                                      }/> */}
                                  </div>
                                )
                              })

                            }
                          </div>
                        )
                      }
                    </div>
                  )
                })
              }
            </div>
            <div className='d-flex flex-column m-2' style={{ gap: 4 }}>
              {milestonStatus?.map((status, index) => (
                <div key={index} className='d-flex align-items-center'>
                  <div style={{ background: status.color, width: 20, height: 20, borderRadius: '50%', marginRight: 5 }}></div>
                  <p style={{marginBottom: 0, fontWeight: 500, fontSize: 14}}>{status.label}</p>
                </div>
              ))}
            </div>
          </>
         )
      }
      {
        !( stage_and_milestone && stage_and_milestone.length > 0 ) && (
          <EmptyState
            title={ 'No stage and milestone define' }
            description={ 'Please do inform admin to create stage and milstone for your project' } />
        )
      }
      { 
        props.showSubmissionModal && <SubmissionModal 
          title={ 'Submit Completion Record' }
          projectObj={ props.projectObj }
          disbursementList={ props.disbursementList }
          selectedStageIndex={ props.selectedStageIndex }
          selectedMilestoneIndex={ props.selectedMilestoneIndex }
          updateProjectPerformance={ props.updateProjectPerformance }
          setOpen={ val => props.onChangePerformanceHOC( val, 'showSubmissionModal' ) }  
        />
      }
      { props.onLoadPerformance && <LoadingModal /> }
    </>
  )
}

export default ProjectPerformanceHOC( ProjectPerformance );