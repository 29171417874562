import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Form, Modal, Button } from "react-bootstrap"
import { getTranslation } from 'utils/renderLanguage'

const KPIForm = props => {

  const [ kpi, setKpi ] = useState({
    kpi_title: '',
    kpi_description: '',
    kpi_document: '',
    kpi_submission_date: '',
    kpi_submission_note: '',
    kpi_status: 'Pending',
    project_id: props.projectDetail.project_id,
  })

  useEffect( () => {
    if( props.selectedKPI && props.mode === 'update' ){
      setKpi( props.selectedKPI )
    }
  }, [] )

  const handleSubmit = () => {
    if( props.mode === 'create' )
      props.createNewKPI( kpi )
    else 
      props.updateKPI( kpi )
  }

  const onChangeField = ( val, context ) => {
    let temp = _.cloneDeep( kpi )
    temp[ context ] = val
    setKpi( temp )
  }

  return(
    <Modal show={ true } onHide={() => props.setOpen(false)} size="xl" >
      <Modal.Header closeButton>
        <Modal.Title>{ props.title }</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className='mt-3'>
          <Form.Label>{ getTranslation( props.language, 'Key Performance Indicator(KPI)' ) }</Form.Label>
          <Form.Control
            type={ 'text' }
            placeholder={ getTranslation( props.language, 'Key Performance Indicator(KPI)' ) }
            value={ kpi.kpi_title }
            onChange={ e => onChangeField( e.target.value, 'kpi_title' ) }
          />
        </Form.Group>
        <Form.Group className='mt-3'>
          <Form.Label>{ getTranslation( props.language, 'Description' ) }</Form.Label>
          <Form.Control
            type={ 'text' }
            style={{ minHeight: 200 }}
            placeholder={ getTranslation( props.language, 'Description' ) }
            as="textarea"
            value={ kpi.kpi_description }
            onChange={ e => onChangeField( e.target.value, 'kpi_description' ) }
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={() => props.setOpen( false ) } >
          { getTranslation( props.language, 'Close' ) }
        </Button>
        <Button
          variant="primary"
          disabled={ !kpi.kpi_title || !kpi.kpi_description } 
          onClick={ handleSubmit } >
          { getTranslation( props.language, 'Submit' ) }
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default KPIForm;